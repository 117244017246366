import React from 'react'
import { Link } from '../components/StyledLink'
import styled from 'styled-components';
import { navigate } from 'gatsby-link'

import Layout, { Container } from '../components/layout'
import SEO from '../components/seo'

const SubmitContainer = styled(Container)`
  margin: 0 auto;
  max-width: 800px;
` 

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  padding: 20px 20px 50px 20px;
  text-align: left;
  border: 1px solid #3a3232;
  max-width: 600px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const StyledInput = styled.input`
  display: inline-block;
  width: 100%;
  height: 40px;
  border: 1px solid #3a3232;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  padding: 1px 4px;
  margin: 8px 0;
`

const Textarea = styled.textarea`
  display: inline-block;
  width: 100%;
  border: 1px solid #3a3232;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  padding: 1px 4px;
  margin: 8px 0;
`

const SubmitButton = styled.button`
  display: block;
  margin: 10px auto;
  padding: 18px 24px;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  background-color: rgba(250,250,250,0.3);
  border: 2px solid rgba(255,155,155,0.9);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    border: 2px solid rgba(255,255,155,0.9);
    background-color: rgba(155,255,255,0.9);
  }
`

const FileInput = styled.label`
  background: #fff;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  &:after {
    flex: 1;
    max-width:800px;
    content:"upload your file";
    left:0;
    right:0;
    margin:5px auto;
    text-align:center;
    padding:10px;
    border-radius:10px;
    border:5px dashed rgba(155,155,255,0.9);
    color:rgba(155,155,255,0.9);
    font-size:30px; 
  }
  &:hover:after {
    background:rgba(155,155,255,0.9);
    border: 5px solid transparent;
    color:#fff;
    cursor:pointer;
  }
  input {
    width: 0px;
    height: 0px;
  }
`

const ErrorMessage = styled.div`
  background: red;
  padding: 10px;
  color: white;
  border-radius: 5px;
`

const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k)=>{
    formData.append(k,data[k])
  });
  return formData
}

class SubmitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      title: '',
      coverLetter: '',
      attachment: {},
      errors: {}
    }
  }
  
  validateInputs = () => {
    let errors = {};
    if (!this.state.name) {
      errors.name = 'Name is required';
    }
    else if (!this.state.email) {
      errors.email = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)
    ) {
      errors.email = 'Invalid email address';
    } else if (!this.state.attachment) {
      errors.attachment = 'Attachment is required!';
    } 
    this.setState({
      errors
    });
    console.log(errors)
    return errors;
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    const formData = this.state;
    delete formData.errors;
    const errors = this.validateInputs();
    if (!Object.keys(errors).length) {
      console.log(formData)
      fetch("/", {
        method: "POST",
        // headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "phrase-submit", ...formData})
      })
        .then(() => {
          navigate(document.getElementById('phrase-submit').getAttribute('action'));
        })
        .catch(error => alert(error));
    }
  }
  
  handleBlur = e => {
    const { errors } = this.state;
    if (Object.keys(errors).length) Object.keys(errors).forEach(err => {
      console.log(err)
    })
  }
  
  handleChange = e => {
    const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
  }
  
  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  }
  
  render() {
    const invalidFields = Object.keys(this.state.errors);
    return (
      <StyledForm 
        id='phrase-submit'
        method='post' 
        action='/thanks' 
        name='phrase-submit' 
        data-netlify-honeypot='bot-field' 
        data-netlify='true'
        onSubmit={this.handleSubmit}
        >
         <input type="hidden" name="form-name" value="phrase-submit" />
           <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
        {invalidFields.length > 0 && invalidFields.map(fieldName => <ErrorMessage>{this.state.errors[fieldName]}</ErrorMessage>)}
        <label>
          Name
        <StyledInput
          type='text'
          name='name'
          id='name' 
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.state.name}
          required
        />
      </label>
      <label>
        Email
        <StyledInput
          type='email'
          name='email'
          id='email' 
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.state.email}
          required
        />
      </label>
      <label>
        Title
        <StyledInput 
          type='text' 
          name='title' 
          id='title' 
          value={this.state.title} 
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          required
        />
      </label>
      <label>
        Cover Letter
        <Textarea 
          name='coverLetter' 
          id='coverLetter' 
          rows='5' 
          value={this.state.coverLetter}
          onChange={this.handleChange}
          onBlur={this.handleBlur} 
        />
      </label>
        <FileInput> 
          <input 
            type='file' 
            name='attachment' 
            id='attachment' 
            onChange={this.handleAttachment}
            onBlur={this.handleBlur}
            required
            accept='.pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' />
            
        </FileInput>
        {this.state.attachment && this.state.attachment.name}
        <SubmitButton type='submit'>
          SUBMIT
        </SubmitButton>
      </StyledForm>
    )
  }
}

const SubmitPage = () => (
  <Layout>
    <SEO title='Submit' />
    <SubmitContainer>
      <h1>Submitting to PHRASE</h1>
      <h3>Fiction & Nonfiction:</h3>
      <p>We accept work of all length, shape, size, and timbre.
      For work over 5000 words, submit away, but know that it gets sorted into a separate 'Longer Works' folder that may take us a while to get to. 
      We are a rag-and-bone operation and need to set aside extra time to read longer works.</p>
      <h3>Poetry:</h3>
      <p>Send up to five poems at a time in a single file. If one or more of these poems are accepted elsewhere before you hear from us, send us a note informing us of this fact.</p>
      <p>Simultaneous submissions are accepted, encouraged, and really the only way you should be submitting if you plan on being published.</p>
      <p>We never charge reading fees and basically think it should be illegal. If you have enough money to pay for your creative submissions, please go buy someone in need a slice of pizza right now, then come back and send us your work.</p>
      <p>We ask for first print and electronic rights. Copyright reverts to the author upon publication. We ask that if/when you publish these works elsewhere you mention that they were published here first.</p>
      <SubmitForm />
    </SubmitContainer>
  </Layout>
)

export default SubmitPage
